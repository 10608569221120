import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import { BsChevronRight } from "react-icons/bs";
import Callaxios from "../../Component.js/Callaxios";
import { toast } from "react-toastify";
import "../Blog/CustomCarousal.css";

export default function BlogsCarousal() {
  let navigate = useNavigate();
  const [blogs, setblogs] = useState([]);

  useEffect(() => {
    getBlogs();
  }, []);

  // const responsive = {
  //   desktop: {
  //     breakpoint: { max: 3000, min: 1024 },
  //     items: 3,
  //     slidesToSlide: 1,
  //   },
  //   tablet: {
  //     breakpoint: { max: 1024, min: 464 },
  //     items: 2,
  //     slidesToSlide: 1,
  //   },
  //   mobile: {
  //     breakpoint: { max: 464, min: 0 },
  //     items: 1,
  //     slidesToSlide: 1,
  //   },
  // };

  const notifyerror = (msg) =>
    toast.error(msg, {
      position: "top-left",
      theme: "dark",
    });

  const getBlogs = async () => {
    try {
      let data = await Callaxios("get", "blogs/blog/");
      if (data.status === 200) {
        setblogs(data.data ?? []);
      }
    } catch (error) {
      console.error(error);
      // notifyerror(error)
    }
  };
  return (
    <>
      <section className="wrapper">
        <div className="container py-13 py-md-15">
          <div className="row gx-lg-8 gx-xl-12 gy-10 mx-3 mx-md-4">
            <div className="col-md-12 col-xl-12 col-lg-12">
              <h3
                className="display-4 text-center"
                style={{ fontSize: "24px", fontWeight: 600 }}
              >
                FROM THE BLOGS
              </h3>
            </div>
            <div className="col-md-12 col-xl-12 col-lg-12 p-0 mt-2">
              {/* <Carousel
                swipeable={false}
                draggable={false}
                showDots={false}
                responsive={responsive}
                ssr={true} 
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={0}
                keyBoardControl={true}
                customTransition="transform 2s linear"
                transitionDuration={2000}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile","desktop"]}
                dotListClass="custom-dot-list-style"
                itemClass="blog-carousel-item"
                arrows={false} 
              >
                <div
                  className="blog-carousel-item"
                >
                  <img
                    src="\assets\img\photos\carousal-img-1.jpg"
                    alt="Five Fun Framing Facts"
                  />
                  <div className="blog-carousal-overlay">
                                    <p>FIVE FUN<br/> FRAMING FACTS</p>
                                </div>
                </div>
                <div
                  className="blog-carousel-item"
                >
                  <img
                    src="\assets\img\photos\carousal-img-2.jpg"
                    alt="Famous Frames"
                  />
                  <div className="blog-carousal-overlay">
                  <p>FAMOUS FRAMES</p>
                                </div>
                </div>
                <div
                  className="blog-carousel-item"
                >
                  <img
                    src="\assets\img\photos\carousal-img-3.jpg"
                    alt="The Starry Night"
                  />
                  <div className="blog-carousal-overlay">
                  <p>THE<br/> STARRY NIGHT</p>
                                </div>
                </div>
                <div
                  className="blog-carousel-item"
                >
                  <img
                    src="\assets\img\photos\carousal-img-4.jpg"
                   alt="Old Versus New"
                  />
                  <div className="blog-carousal-overlay">
                  <p>OLD VERSUS<br/> NEW</p>
                                </div>
                </div>
              </Carousel> */}

              <div className="swiper gallery-slider">
                <Swiper
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true, dynamicBullets: true }}
                  breakpoints={{
                    1024: { slidesPerView: 3 },
                    768: { slidesPerView: 2 },
                    560: { slidesPerView: 1 },
                  }}
                  autoplay={{ delay: 5000, disableOnInteraction: false }}
                  loop={true}
                  // centeredSlides={true}
                  modules={[Pagination, Autoplay]}
                  onSwiper={(swiper) => {
                    document.documentElement.style.setProperty(
                      "--swiper-pagination-bullets",
                      swiper.slides.length
                    );
                  }}
                >
                  {blogs.map((blog, index) => (
                    <SwiperSlide key={index}>
                      <div className="img-item">
                        <img
                          src={blog?.image}
                          alt={`image${index + 1}`}
                          onError={(e) => {
                            e.target.src =
                              "https://montevista.greatheartsamerica.org/wp-content/uploads/sites/2/2016/11/default-placeholder.png ";
                          }}
                        />
                        <div className="caption">
                          <h3>{blog?.blog_name ?? ""}</h3>
                          {/* <a href="#" className="read-more">Read more &rarr;</a> */}
                          <button
                            type="button"
                            className="btn btn-primaryog read-more"
                            onClick={() =>
                              navigate(
                                `/blogs/${blog?.blog_name.replace(
                                  /\s+/g,
                                  "-"
                                )}`,
                                {
                                  state: { id: blog.id },
                                }
                              )
                            }
                          >
                            Read more{" "}
                            <BsChevronRight
                              style={{
                                marginLeft: "10px",
                                marginRight: "-9px",
                              }}
                            />
                          </button>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
