import React, { useState } from "react";
import { BsChevronRight } from "react-icons/bs";
import { BaseUrl } from "../../Component.js/Url";
import axios from "axios";
import { toast } from "react-toastify";

export default function PromoSignUpSection() {
  const [mail, setmail] = useState("");

  const notify = (msg) =>
    toast.success(msg, {
      position: "top-left",
      theme: "dark",
    });
  const notifyerror = (msg) =>
    toast.error(msg, {
      position: "top-left",
      theme: "dark",
    });

  const sendMail = async () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!mail) {
      notifyerror("Email is required.");
      return;
    }

    if (!emailRegex.test(mail)) {
      notifyerror("Please enter a valid email address.");
      return;
    }
    try {
      let response = await axios.post(BaseUrl + "contact/mail/", {
        email: mail,
      });

      if (response.data.Status === 200) {
        notify(
          "Thank you! You've successfully signed up for our deals and promotions."
        );
        setmail("");
      } else {
        notifyerror("Oops! Something went wrong. Please try again later.");
      }
    } catch (error) {
      console.error(error);
      //  notifyerror(error);
    }
  };
  return (
    <>
      <section className="wrapper">
        <div className="container promo-signup pb-14 pb-md-17">
          <div className="promo-image-wrapper position-relative">
            <img src="\assets\img\photos\banner-2.webp" alt="img" />
            <div className="content-overlay">
              <h3 className="promo-headline">
                Get early access
                <br /> to our deals and promos!
              </h3>
              <p className="promo-subheadline">
                Sign up and 10% of your next purchase is on us!
                <br />
                Sound good, huh?
              </p>

              <div className="d-flex mt-10 mt-md-14 promo-input-mr">
                <input
                  required
                  type="emaillogin"
                  className="form-control promo-input"
                  placeholder="E mail address here"
                  value={mail}
                  onChange={(e) =>
                    !/\s/.test(e.target.value) ? setmail(e.target.value) : ""
                  }
                />
                <button className="btn promo-button" onClick={sendMail}>
                  <BsChevronRight
                    style={{
                      color: "#fff",
                    }}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* /.container */}
      </section>
    </>
  );
}
