import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export default function MiniFrame2() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="container mt-15 pt-8">
        <div className="card-body mx-md-5 mx-3 frames-card-body">
          <h4 className="display-5 mb-15 section-title-main">
            USE ONE OF OURS
          </h4>

          <div className="frame-categories">
            <Link
              to={{ pathname: "/upload-mini" }}
              state={{ string: "landscape" }}
            >
              <div className="main-image-container fimg-landscape">
                <img src="\assets\img\photos\landscape-morning-fog-mountains-with-hot-air-balloons-sunrise.jpg" />
                <p>Landscape</p>
              </div>
            </Link>
            <Link
              to={{ pathname: "/upload-mini" }}
              state={{ string: "portait" }}
            >
              <div className="main-image-container fimg-portrait">
                <img src="\assets\img\photos\vertical tree.jpg" />
                <p>Portrait</p>
              </div>
            </Link>
            <Link
              to={{ pathname: "/upload-mini" }}
              state={{ string: "square" }}
            >
              <div className="main-image-container fimg-square">
                <img src="\assets\img\photos\baloon11.jpg" />
                <p>Square</p>
              </div>
            </Link>
          </div>
        </div>

        <div className="row mx-md-5 mx-3 py-8">
          <h2 className="display-4 p-0 main-frame-title">FRAMES</h2>

          <div className="frameimg-container col-lg-4 col-md-6 col-12">
            <div className="main-fimg-bg">
              <div className="main-fimg-container">
                <img src="\assets\img\photos\mf-img-1.jpg" alt="img" />
              </div>
            </div>
          </div>
          <div className="framecon-container col-lg-8 col-md-6 col-12">
            <div className="framecon-mt">
              <h1 className="display-4 mb-6 mb-lg-8 mb-xl-11 mb-md-6 px-lg-14 main-frame-subtitle">
                Shop Photo Frames Online: Find the Perfect Frame for Any Memory
              </h1>

              <div className="main-fpara-container">
                <p>
                  Enhance your living spaces with our classic wall photo frames
                  crafted with perfection to highlight your most priceless
                  moments.
                </p>

                <p>
                  The elegant wall photo frames are made from premium materials
                  and designed to present your images in a classy way. Our
                  versatile selection of photo frames online offers the ideal
                  choice for you, whether you want to exhibit a single special
                  photo or build a scenic gallery wall.
                </p>

                <p>
                  Ranging from elegant wall photo frames to adaptable micro
                  frames and eye-catching collages, our frame shop in Dubai
                  brings an extensive selection of frames for display.
                </p>

                <p>
                  While our aluminum frames exude modern sophistication, our
                  timber frames add a touch of comfort to any space. Use our
                  enchanting picture frames to turn any space into a gallery of
                  memories!
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="main-fimgs-wrapper d-flex flex-row mx-md-5 mx-3 mb-15">
          <div className="main-fimgs">
            <img src="\assets\img\photos\mf-img-2.jpeg" />
          </div>
          <div className="main-fimgs">
            <img src="\assets\img\photos\mf-img-3.jpg" />
          </div>
          <div className="main-fimgs">
            <img src="\assets\img\photos\mf-img-5.jpeg" />
          </div>
        </div>
      </div>

      <Helmet>
        <title>Premium Photo Frames | Stylish Wall Frames Online</title>
        <meta
          name="description"
          content="Transform photos into masterpieces with our premium online frames. Choose from classic or contemporary styles for easy installation. Explore now!"
        />
        <meta name="keywords" content="photo frame online, wall photo frames" />
        <meta
          property="og:url"
          content="https://fotoframesuae.com/photoframesonline"
        />
        <meta
          property="og:title"
          content="Premium Photo Frames | Stylish Wall Frames Online"
        />
        <meta
          property="og:description"
          content="Transform photos into masterpieces with our premium online frames. Choose from classic or contemporary styles for easy installation. Explore now!"
        />
        <link
          rel="canonical"
          href="https://fotoframesuae.com/photoframesonline"
        />
      </Helmet>
    </div>
  );
}
