import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { BsImageFill } from "react-icons/bs";
import { FaImage } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function PrintFrame() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div>
        <div className="container mt-15 pt-8">
          <div className="card-body mx-md-5 mx-3 frames-card-body">
            <h4 className="display-5 mb-15 section-title-main">
              USE ONE OF OURS
            </h4>

            <div className="frame-categories">
              <Link
                to={{ pathname: "/upload-print" }}
                state={{ string: "landscape" }}
              >
                <div className="main-image-container fimg-landscape">
                  <img src="\assets\img\photos\demo-landscape2.jpg" />
                  <p>Landscape</p>
                </div>
              </Link>
              <Link
                to={{ pathname: "/upload-print" }}
                state={{ string: "portait" }}
              >
                <div className="main-image-container fimg-portrait">
                  <img src="\assets\img\photos\demo-portrait2.jpg" />
                  <p>Portrait</p>
                </div>
              </Link>
              <Link
                to={{ pathname: "/upload-print" }}
                state={{ string: "square" }}
              >
                <div className="main-image-container fimg-square">
                  <img src="\assets\img\photos\demo-square2.jpg" />
                  <p>Square</p>
                </div>
              </Link>
            </div>
          </div>

          <div className="row mx-md-5 mx-3 py-8">
            <h2 className="display-4 p-0 main-frame-title">PRINT</h2>

            <div className="frameimg-container col-lg-4 col-md-6 col-12">
              <div className="main-fimg-bg">
                <div className="main-fimg-container">
                  <img src="\assets\img\photos\pf-img-5.jpeg" alt="img" />
                </div>
              </div>
            </div>
            <div className="framecon-container col-lg-8 col-md-6 col-12">
              <div className="framecon-mt">
                <h1 className="display-4 mb-6 mb-lg-8 mb-xl-11 mb-md-6 px-lg-14 main-frame-subtitle">
                  Preserve Your Memories: Premium Photo Printing In Dubai
                </h1>

                <div className="main-fpara-container">
                  <p>
                    Convert your digital images into memorabilia that you can
                    cherish forever!
                  </p>

                  <p>
                    With the help of 'FotoFrames' photo printing service in
                    Dubai, keep your most treasured moments forever with
                    superior prints. The premium print pictures we offer
                    effortlessly bring your memories to life, which you can
                    treasure for years to come.
                  </p>

                  <p>
                    To save your most priceless memories, make use of our
                    excellent online photo printing service. The superior,
                    glossy, and matte photo paper that we use for photo printing
                    in Dubai will allow you to produce a magnificent memorial
                    album that will display your images in all of their vivid
                    clarity.
                  </p>

                  <p>
                    Professional-quality photo printing is easy to integrate
                    into your daily routine and doesn't require any effort. You
                    can count on us to get the best picture printing services
                    available in Dubai. With our online ordering and delivery
                    system, you will get prints whenever you prefer.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="main-fimgs-wrapper d-flex flex-row mx-md-5 mx-3 mb-15">
            <div className="main-fimgs">
              <img src="\assets\img\photos\pf-img-6.jpeg" />
            </div>
            <div className="main-fimgs">
              <img src="\assets\img\photos\pf-img-7.webp" />
            </div>
            <div className="main-fimgs">
              <img src="\assets\img\photos\pf-img-8.jpeg" />
            </div>
          </div>
        </div>

        <Helmet>
          <title>
            Premium Photo Printing in Dubai | Vibrant,High-Quality Prints
          </title>
          <meta
            name="description"
            content="Transform digital photos into high-quality prints with our photo printing Dubai service. Order online to print pictures on Glossy or Matte paper!"
          />
          <meta
            name="keywords"
            content="photo printing dubai, print pictures"
          />
          <meta
            property="og:url"
            content="https://fotoframesuae.com/photo-printing-dubai"
          />
          <meta
            property="og:title"
            content="Premium Photo Printing in Dubai | Vibrant,High-Quality Prints"
          />
          <meta
            property="og:description"
            content="Transform digital photos into high-quality prints with our photo printing Dubai service. Order online to print pictures on Glossy or Matte paper!"
          />
          <link
            rel="canonical"
            href="https://fotoframesuae.com/photo-printing-dubai"
          />
        </Helmet>
      </div>
    </>
  );
}
