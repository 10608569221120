import React, { useEffect } from "react";
import { Link } from "react-router-dom";

export default function Frames() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="container mt-18 pt-5 pt-md-3">
        <div className="row mb-12">
          <div className="frames-title-container">
            <h3 className="mb-0">FRAMES</h3>
          </div>
        </div>

        <div className="row gx-md-5 gy-5 mb-7 frames-row">
          <div className="col-md-6 col-xl-6 mb-12">
            <div className="frame-cards shadow-lg position-relative">
              <Link to="/photo-printing-dubai">
                <img src="\assets\img\photos\print-ff-4.jpg" alt="img" />
                <div className="frame-card-title-container">
                  <h6 className="frame-card-title mb-0">Print</h6>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-md-6 col-xl-6 mb-12">
            <div className="frame-cards shadow-lg position-relative">
              <Link to="/canvas-printing-dubai">
                <img src="\assets\img\photos\canvas-ff-3.webp" alt="img" />
                <div className="frame-card-title-container">
                  <h6 className="frame-card-title mb-0">Canvas</h6>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-md-6 col-xl-6 mb-12">
            <div className="frame-cards shadow-lg position-relative">
              <Link to="/photoframesonline">
                <img src="\assets\img\photos\mini-ff-2.webp" alt="img" />
                <div className="frame-card-title-container">
                  <h6 className="frame-card-title mb-0">Wall Frames</h6>
                </div>
              </Link>
            </div>
          </div>

          <div className="col-md-6 col-xl-6 mb-12">
            <div className="frame-cards shadow-lg position-relative">
              <Link to="/collage-photo-frame">
                <img src="\assets\img\photos\collage-ff-3.jpg" alt="img" />
                <div className="frame-card-title-container">
                  <h6 className="frame-card-title mb-0">Collage Frames</h6>
                </div>
              </Link>
            </div>
          </div>
        </div>

        <div className="row mb-10 text-center" style={{ padding: "20px" }}>
          <p className="frames-paragraph mb-0">
            Preserve your memories beautifully !
          </p>
        </div>
      </div>
    </div>
  );
}
