import React from "react";
import { FaPhoneAlt, FaRegEnvelope } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function MobileMenu({
  rightSidebar,
  setrightSidebar,
  profileFunction,
}) {
  return (
    <div>
      <div
        class={`offcanvas offcanvas-start menu-canvas ${
          rightSidebar ? "show" : "hide-canvas"
        }`}
        tabindex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasExampleLabel"></h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => setrightSidebar(false)}
          ></button>
        </div>
        <div class="offcanvas-body" style={{ margin: "0 50px" }}>
          <div className="offcanvas-sections d-flex flex-column">
            <div className="mb-3">
              <ul className="list-unstyled sidebar-list">
                <li>
                  <Link to="/" onClick={() => setrightSidebar(false)}>
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/shopping" onClick={() => setrightSidebar(false)}>
                    Our Store
                  </Link>
                </li>
                <li>
                  {/* <Link onClick={() => profileFunction()}>Profile</Link> */}
                  <Link
                    onClick={() => profileFunction() & setrightSidebar(false)}
                    className="nav-link"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    Profile
                  </Link>
                </li>
              </ul>
            </div>

            <div className="mb-3">
              <h4 className="widget-title text-white mb-3">Products</h4>
              <ul className="list-unstyled sidebar-list">
                <li>
                  <Link
                    to="/photo-printing-dubai"
                    onClick={() => setrightSidebar(false)}
                  >
                    Print
                  </Link>
                </li>
                <li>
                  <Link
                    to="/photoframesonline"
                    onClick={() => setrightSidebar(false)}
                  >
                    Wall Frames
                  </Link>
                </li>
                <li>
                  <Link
                    to="/canvas-printing-dubai"
                    onClick={() => setrightSidebar(false)}
                  >
                    Canvas
                  </Link>
                </li>
                <li>
                  <Link
                    to="/collage-photo-frame"
                    onClick={() => setrightSidebar(false)}
                  >
                    Collage Frames
                  </Link>
                </li>
              </ul>
            </div>

            <div>
              <h4 className="widget-title text-white mb-3">Foto Frames</h4>
              <ul className="list-unstyled sidebar-list">
                <li>
                  <Link to="/about-us" onClick={() => setrightSidebar(false)}>
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="/blogs" onClick={() => setrightSidebar(false)}>
                    Blogs
                  </Link>
                </li>
                <li>
                  <Link to="/contact" onClick={() => setrightSidebar(false)}>
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div style={{ margin: "0 50px" }}>
          <a href="mailto:fotoframe@gmail.com" style={{ color: "#fff" }}>
            <FaRegEnvelope style={{ marginRight: "10px" }} />
            fotoframe@gmail.com
          </a>
        </div>
        <div className="mb-3" style={{ margin: "0 50px" }}>
          <a href="tel:+971-56-921-4243" style={{ color: "#fff" }}>
            <FaPhoneAlt style={{ marginRight: "7px", fontSize: "14px" }} />
            +971 56 921 4243
          </a>
        </div>
      </div>
    </div>
  );
}
