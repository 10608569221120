import React from "react";
import { Link } from "react-router-dom";

export default function ServiceHighlights() {
  return (
    <>
      <div className="row gx-md-5 gy-5 mx-0 mx-md-2">
        <div className="col-md-12 col-xl-12 text-center">
          <h3 className="display-4 mb-6 service-frame-title">
            WHAT WE PROVIDES
          </h3>
        </div>

        <div className="col-md-4 col-lg-3 col-xl-3 mb-8 mb-md-8 mb-lg-14 mb-xl-18">
          <div className="service-frame-card shadow-lg position-relative">
            <Link to="/photo-printing-dubai">
              <img
                className="service-frame-image"
                src="\assets\img\photos\print-ff-3.jpg"
                alt="img"
              />
              <div className="service-frame-overlay">
                <h4 className="service-frame-text mb-0">Print</h4>
              </div>
            </Link>
            {/*/.card-body */}
          </div>
          {/*/.card */}
        </div>
        <div className="col-md-4 col-lg-3 col-xl-3 mb-8 mb-md-8 mb-lg-14 mb-xl-18">
          <div className="service-frame-card shadow-lg position-relative">
            <Link to="/photoframesonline" className="">
              <img
                className="service-frame-image"
                src="\assets\img\photos\mini-ff-2.webp"
                alt="img"
              />
              <div className="service-frame-overlay">
                <h4 className="service-frame-text mb-0">Wall Frames</h4>
              </div>
            </Link>
            {/*/.card-body */}
          </div>
          {/*/.card */}
        </div>
        {/*/column */}
        <div className="col-md-4 col-lg-3 col-xl-3 mb-8 mb-md-8 mb-lg-14 mb-xl-18">
          <div className="service-frame-card shadow-lg position-relative">
            <Link to="/canvas-printing-dubai">
              <img
                className="service-frame-image"
                src="\assets\img\photos\canvas-ff-3.webp"
                alt="img"
              />
              <div className="service-frame-overlay">
                <h4 className="service-frame-text mb-0">Canvas</h4>
              </div>
            </Link>
            {/*/.card-body */}
          </div>
          {/*/.card */}
        </div>
        {/*/column */}
        <div className="col-md-4 col-lg-3 col-xl-3 mb-17 mb-md-17 mb-lg-18 mb-xl-18">
          <div className="service-frame-card shadow-lg position-relative">
            <Link to="/collage-photo-frame">
              <img
                className="service-frame-image"
                src="\assets\img\photos\collage-ff-3.jpg"
                alt="img"
              />
              <div className="service-frame-overlay">
                <h4 className="service-frame-text mb-0"> Collage Frames</h4>
              </div>
            </Link>
            {/*/.card-body */}
          </div>
          {/*/.card */}
        </div>
        {/*/column */}
      </div>
    </>
  );
}
