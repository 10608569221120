import React, { useEffect, useState } from "react";
import { FaEnvelope, FaPhoneAlt } from "react-icons/fa";
import { BaseUrl } from "../Component.js/Url";
import axios from "axios";
import { toast } from "react-toastify";
import { Form } from "react-bootstrap";

export default function Contact2() {
  const [contactData, setcontactData] = useState();
  const [buttonLoading, setbuttonLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const notify = (msg) =>
    toast.success(msg, {
      position: "top-left",
      theme: "dark",
    });
  const notifyerror = (msg) =>
    toast.error(msg, {
      position: "top-left",
      theme: "dark",
    });

  const validateForm = () => {
    let isValid = true;

    if (!contactData?.name) {
      notifyerror("Please enter your name.");
      isValid = false;
      return isValid;
    }

    if (!contactData?.email) {
      notifyerror("Please enter your email address.");
      isValid = false;
      return isValid;
    } else {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(contactData?.email)) {
        notifyerror("Please enter a valid email address.");
        isValid = false;
        return isValid;
      }
    }

    if (!contactData?.mobile) {
      notifyerror("Please enter your mobile number.");
      isValid = false;
      return isValid;
    } else if (
      contactData?.mobile?.length < 7 ||
      contactData?.mobile?.length > 15
    ) {
      notifyerror("Mobile number must be between 7 and 15 digits.");
      isValid = false;
      return isValid;
    }

    if (!contactData?.message) {
      notifyerror("Please enter your query.");
      isValid = false;
      return isValid;
    }

    return isValid;
  };

  const contactUS = async () => {
    if (validateForm()) {
      setbuttonLoading(true);
      try {
        let body = {
          method: "post",
          url: BaseUrl + "contact/mail/",
          data: contactData,
        };
        let data = await axios(body);
        setbuttonLoading(false);
        if (data.data.Status === 200) {
          notify("Thank you! Your message has been sent successfully.");
          setcontactData("");
          // setvalidated(false);
        } else {
          notifyerror("Oops! Something went wrong. Please try again later.");
        }
      } catch (error) {
        console.error(error);
        setbuttonLoading(false);
        // notifyerror(error);
      }
    }
  };

  return (
    <div>
      <div className="container contact-background-container mt-15 px-0">
        <div className="contact-main-content">
          <div className="contact-form-wrapper">
            <p className="contact-description">
              Send us a message below, and we'll respond as quickly as possible
            </p>
            <Form
              className="contact-form"
              noValidate
              onSubmit={(e) => e.preventDefault() & contactUS()}
            >
              <input
                required
                type="text"
                className="form-control mb-4"
                style={{ height: "57px", borderRadius: 0 }}
                placeholder="Name"
                value={contactData?.name ?? ""}
                // onChange={(e) =>
                //   setcontactData({ ...contactData, name: e.target.value })
                // }
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const isFirstSpace = inputValue.indexOf(" ") === 0;

                  if (isFirstSpace) {
                    setcontactData({
                      ...contactData,
                      name: inputValue.substring(1),
                    });
                  } else {
                    setcontactData({ ...contactData, name: inputValue });
                  }
                }}
              />

              <input
                required
                type="email"
                className="form-control mb-4"
                style={{ height: "57px", borderRadius: 0 }}
                placeholder="Email address"
                value={contactData?.email ?? ""}
                onChange={(e) => {
                  const inputValue = e.target.value;

                  setcontactData({ ...contactData, email: inputValue.trim() });
                }}
                onKeyDown={(e) => {
                  if (e.target.value.length === 0 && e.key === " ") {
                    e.preventDefault();
                  }
                }}
              />
              <input
                required
                type="number"
                className="form-control mb-4"
                style={{ height: "57px", borderRadius: 0 }}
                placeholder="Mobile"
                value={contactData?.mobile ?? ""}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const isFirstSpace = inputValue.indexOf(" ") === 0;

                  if (isFirstSpace && inputValue >= 0) {
                    setcontactData({
                      ...contactData,
                      mobile: inputValue.substring(1),
                    });
                  } else if (inputValue < 0) {
                    // If the number is negative, convert it to positive
                    setcontactData({
                      ...contactData,
                      mobile: Math.abs(inputValue),
                    });
                  } else if (inputValue.length > 15) {
                    // Enforce maximum length of 15 digits
                    setcontactData({
                      ...contactData,
                      mobile: inputValue.slice(0, 15),
                    });
                  } else {
                    setcontactData({ ...contactData, mobile: inputValue });
                  }
                }}
              />
              <textarea
                required
                type="text"
                className="form-control mb-4"
                style={{ height: "210px", borderRadius: 0 }}
                placeholder="Your query"
                value={contactData?.message ?? ""}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const isFirstSpace = inputValue.indexOf(" ") === 0;

                  if (isFirstSpace) {
                    setcontactData({
                      ...contactData,
                      message: inputValue.substring(1),
                    });
                  } else {
                    setcontactData({ ...contactData, message: inputValue });
                  }
                }}
              />
              <button
                type="submit"
                className="btn btn-primaryog contact-button-wrapper"
              >
                {buttonLoading ? "Loading..." : "Submit"}
              </button>
            </Form>

            <div className="contact-get-in-touch">
              <div className="contact-details-box">GET IN TOUCH</div>
              <div className="contact-icon-wrapper-ph">
                <span className="mb-1">
                  <FaPhoneAlt style={{ fontSize: "35px", color: "#fff" }} />
                </span>
                <p className="contact-title-text-ph mb-0">PHONE NUMBER</p>
                <p className="contact-info-text-ph mb-0">+971 56 921 4243</p>
              </div>
              <div className="contact-icon-wrapper-em">
                <span className="mb-1">
                  <FaEnvelope style={{ fontSize: "35px", color: "#fff" }} />
                </span>
                <p className="contact-title-text-em mb-0">E MAIL ADDRESS</p>
                <p className="contact-info-text-em mb-0">
                  fotoframes@gmail.com
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="contact-get-in-touch-2">
          <div className="contact-details-box-2">GET IN TOUCH</div>
          <div className="contact-icon-wrapper-ph-2">
            <span className="mb-1">
              <FaPhoneAlt style={{ fontSize: "35px", color: "#fff" }} />
            </span>
            <p className="contact-title-text-ph-2 mb-0">PHONE NUMBER</p>
            <p className="contact-info-text-ph-2 mb-0">+971 56 921 4243</p>
          </div>
          <div className="contact-icon-wrapper-em-2">
            <span className="mb-1">
              <FaEnvelope style={{ fontSize: "35px", color: "#fff" }} />
            </span>
            <p className="contact-title-text-em-2 mb-0">E MAIL ADDRESS</p>
            <p className="contact-info-text-em-2 mb-0">fotoframes@gmail.com</p>
          </div>
        </div>
      </div>
    </div>
  );
}
