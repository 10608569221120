import React from "react";

export default function FotoFrameOverview() {
  return (
    <>
      <section className="wrapper" style={{ background: "#3e41920a" }}>
        <div className="container">
          <div className="row pt-2 gx-md-5 gy-5 mx-0 mx-md-2 mb-13 mb-md-14 mb-lg-15 mb-xl-15">
            <h3
              className="display-4 text-center mb-md-6 mb-4"
              style={{ fontWeight: 800 }}
            >
              What is FotoFrame?
            </h3>

            <div className="col-lg-4 col-md-6 col-12">
              <div className="position-relative overview-img-container">
                <img src="\assets\img\photos\overview-2.webp" alt="img" />
                <div className="overview-overlay">
                  <h5 className="overview-overlay-text">Live the memories</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-6 col-12 mb-4">
              <div>
                <div className="overview-paragraph">
                  <p>
                    FotoFrame is a group of art fanatics on a mission to help
                    photographers and art lovers find exactly what they need,
                    without compromising on quality and affordability. We offer
                    the best photo printing and framing services in Dubai to
                    help you cherish life s most valuable moments.
                  </p>

                  <p>
                    At FotoFrame, we specialize in a variety of print and frame
                    photo services including photo printing, canvas printing,
                    collage framing, and custom framing. Our knowledgeable staff
                    is available to assist you, whether you want to print and
                    frame images from your most recent trip or make a
                    captivating collage of your wedding day.{" "}
                  </p>

                  <p>
                    Being the best picture printing and frame shop in UAE, we
                    are aware that your pictures are windows into your most
                    treasured memories, not just pictures. We are dedicated to
                    assisting you in bringing those memories to life by creating
                    eye catching, display worthy products. With photo printing
                    and framing, your memories are expertly maintained since
                    every product is made with great care and attention to
                    detail.
                  </p>

                  <p>
                    Our photo printing and framing options can accommodate any
                    preference and cost expectations. We have several choices to
                    fit your style, ranging from sleek current styles to
                    timeless, classic frames. Give FotoFrame a chance to turn
                    your digital photos into stunning artwork that you can show
                    off in your house or any space to exhibit art and
                    memorabilia.
                  </p>

                  <p>
                    Our team of photo printing and framing professionals turns
                    your digital images into classic art with premium quality
                    photo printing and framing. With our elegant frames, vibrant
                    collages, and sophisticated canvases, FotoFrame offers the
                    ideal solution to capture unique moments, highlight your
                    holidays, or even celebrate a milestone that you have
                    achieved in life.
                  </p>

                  <p>
                    We offer you custom frames delivered right to your door.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
