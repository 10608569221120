import React, { useEffect, useState } from "react";
import "./CustomCarousal.css";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import { BsChevronRight } from "react-icons/bs";
import Callaxios from "../../Component.js/Callaxios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function Blogs() {
  let navigate = useNavigate();
  const [blogs, setblogs] = useState([]);

  useEffect(() => {
    getBlogs();
    window.scrollTo(0, 0);
  }, []);

  const notifyerror = (msg) =>
    toast.error(msg, {
      position: "top-left",
      theme: "dark",
    });

  const getBlogs = async () => {
    try {
      let data = await Callaxios("get", "blogs/blog/");
      if (data.status === 200) {
        setblogs(data.data ?? []);
      }
    } catch (error) {
      console.error(error);
      notifyerror(error);
    }
  };

  return (
    <div>
      <div
        className="container mt-15 pt-5"
        style={{ position: "relative", width: "100%" }}
      >
        <div className="card-body mx-4" style={{ padding: "50px 5px" }}>
          <h1
            className="display-4 mb-7"
            style={{ fontSize: "32px", textAlign: "center", fontWeight: 600 }}
          >
            OUR BLOG
          </h1>

          <div className="row">
            {blogs.map((blog, index) => (
              <div className="col-12 col-md-6 col-lg-4 mb-6" key={index}>
                <div className="blog-card">
                  <div className="blog-card-img-wrapper">
                    <img
                      src={blog?.image}
                      alt="img"
                      onError={(e) => {
                        e.target.src =
                          "https://montevista.greatheartsamerica.org/wp-content/uploads/sites/2/2016/11/default-placeholder.png ";
                      }}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                  <div className="caption" style={{ margin: "0 20px" }}>
                    <h3 className="mb-2">{blog?.blog_name ?? ""}</h3>
                    <button
                      type="button"
                      className="btn btn-primaryog read-more"
                      onClick={() =>
                        navigate(
                          `/blogs/${blog?.blog_name.replace(/\s+/g, "-")}`,
                          {
                            state: { id: blog.id },
                          }
                        )
                      }
                    >
                      Read more{" "}
                      <BsChevronRight
                        style={{ marginLeft: "10px", marginRight: "-9px" }}
                      />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
