import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Callaxios from "../../Component.js/Callaxios";

export default function BlogContent() {
  const location = useLocation();
  const { id } = location.state || {};
  const [blogData, setblogData] = useState();

  useEffect(() => {
    getBlogData();
    window.scrollTo(0, 0);
  }, []);

  const notifyerror = (msg) =>
    toast.error(msg, {
      position: "top-left",
      theme: "dark",
    });

  const getBlogData = async () => {
    try {
      let data = await Callaxios("get", "blogs/blog/", { id: id });
      if (data.status === 200) {
        setblogData(data.data[0]);
      }
    } catch (error) {
      console.error(error);
      // notifyerror(error)
    }
  };

  return (
    <div>
      <div className="container mt-18 pt-5">
        <div className="row mb-10">
          <div className="px-8">
            <h3
              className="mb-5"
              style={{ fontSize: "32px", fontWeight: 600, textAlign: "center" }}
            >
              {blogData?.blog_name ?? ""}
            </h3>

            <div style={{ height: "358px", width: "100%" }}>
              <img
                src={blogData?.banner}
                alt="img"
                onError={(e) => {
                  e.target.src =
                    "https://montevista.greatheartsamerica.org/wp-content/uploads/sites/2/2016/11/default-placeholder.png ";
                }}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
          </div>
        </div>

        <div className="row mb-10">
          <div className="col-md-12 col-xl-12 mb-12 px-8">
            <div dangerouslySetInnerHTML={{ __html: blogData?.contant }}></div>
          </div>
        </div>
      </div>
    </div>
  );
}
